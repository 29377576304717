<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.$store.commit('SET_MOBLIE', false)
    } else {
      this.$store.commit('SET_MOBLIE', true)
    }
  }
}
</script>

<style lang="scss">
// @import '@/common/style/reset.css';
* {
  margin: 0;
  padding: 0;
}

// ul, ol, li {
//   list-style: none;
// }

.van-toast {
  min-width: 30%!important;
}

.van-button--disabled {
  opacity: 1!important;
}


.pwp-1px-border {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    #caced5 0%,
    #caced5 50%,
    transparent 50%
  );
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
.van-toast__text {
  word-break: keep-all;
}
.pinch-zoom-container{height: auto !important;}
</style>