import Vue from 'vue'
import Vuex from 'vuex'

import { sessionStorage } from '@common/js/utils'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  state: {
    loading: false, // loading
    requestError: {}, // 请求error
    token: sessionStorage('token'),
    phone: sessionStorage('phone'),
    cancelUrl: sessionStorage('cancel_url'),
    confirmUrl: sessionStorage('confirm_url'),
    orderNo: sessionStorage('order_no'),
    userInfo: {
      customerId: '', // 客户ID
      cnname: '吴海清', // 中文名
      maincard: ''
    },
    countDown:'',
    // 地理位置 经纬度
    position: {
      latitude: 116.422882,
      longitude: 40.036686
    },
    isMobile: false
  },
  getters: {},
  mutations: {
    setToken (state, info) {
      state.token = info
    },
    setPhone (state, info) {
      state.phone = info
    },
    setConfirmUrl (state, info) {
      state.confirmUrl = info
    },
    setCancelUrl (state, info) {
      state.cancelUrl = info
    },
    setOrderNo (state, info) {
      state.orderNo = info
    },
    clearToken (state) {
      window.sessionStorage.clear()
      state.token = null
    },
    // 设置loading
    setLoading (state, loading) {
      state.loading = loading
    },
    // 设置请求错误
    setRequestError (state, obj) {
      state.requestError = obj
    },
    // 保存用户信息
    setUserInfo (state, info) {
      state.userInfo = Object.assign({}, state.userInfo, info)
    },
    // 保存用户信息
    setTimer (state, info) {
      state.countDown =info
    },
    SET_MOBLIE(state, isMobile){
      state.isMobile = isMobile;
    }
  },
  actions: {},
  modules: {},
  strict: debug
})

export default store
