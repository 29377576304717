<template>
  <div 
    class='header' 
  >
    <div class='nav'>
      <img
        src="@/assets/ZoomerCreditlogo.png"
        alt=""
        @click="goRouter('home')"
      >
    </div>
    <ul v-if="isMobile">
      <li @click="goRouter('account')">Account</li>
      <li @click="goRouter('profile')">Profile</li>
      <li @click="goRouter('loanHistory')">History</li>
      <li @click="goRouter('home')"><span class='headerSpan'>Log out</span><i class="el-icon-user-solid"></i></li>
    </ul>
    <div 
      v-else
      class="drawer" 
      @click="navList(navState)"
    >
      <span v-if="navState"><img src="@/assets/colse.svg" /></span>
      <span v-else><img src="@/assets/nav-list.svg" /></span>
    </div>
    <div
      v-if="navState"
      class="navIsMobile" 
      :style="navHeight"
      @click="navList(navState)"
    >
      <ul>
        <li @click="goRouter('account')">Account</li>
        <li @click="goRouter('profile')">Profile</li>
        <li @click="goRouter('loanHistory')">History</li>
        <li @click="goRouter('home')"><span class='headerSpan'>Log out</span><i class="el-icon-user-solid"></i></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      note:{
        backgroundImage:'url('+require('@/assets/page.png')+')',
        backgroundSize:'100% 100%',
      },
      isMobile: false,
      navState: false,
      navHeight: {
        height: 0
      }
    }
  },
  methods: {
    goRouter(value){
      this.$router.push({ name: value })
    }, 
    navList(value) {
      this.navState = !value
      if (this.navState) {
        // 手机端页面禁止滚动
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'scroll';
      }
    }
  },
  mounted() {
    this.isMobile = this.$store.state.isMobile
    // 初始化页面滚动
    document.body.style.overflow = 'scroll';
    if (document.documentElement.clientHeight) {
      this.navHeight.height = document.documentElement.clientHeight + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
ul li{list-style: none;}
.header{      
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  /deep/.el-button{
    width: 12%;
  }
  .nav{
    display: flex;
    align-items: center;
    padding:2em 0;
    img{
      width: 15em;
      margin-right: 2em;
      cursor: pointer;
    }
  }
  ul{
    display: flex;
    font-size: 1.8em;
    li{
      margin-right: 0.5em;
      cursor: pointer;
      padding:0 10px;
    }
  }
}
.headerSpan{
  margin-right: 1em;
  margin-left: 3em;
}
@media screen and (max-width: 768px) {
  .header{
    justify-content: space-between;
    padding: 0 1em;
    .drawer{
      position: relative;
      img{
        width: 2em;
      }
    }
    .navIsMobile{
      position: fixed;
      z-index: 100;
      top: 80px;
      left: 0;
      background: #fff;
      width: 100%;
      ul{
        flex-direction: column;
        li{
          font-size: 1em;
          border-bottom: 2px solid #ccc;
          padding: 1em;
        }
      }
    }
    .headerSpan{
      margin-left: 0;
    }
  }
}
</style>