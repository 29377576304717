<template>
  <div 
    class='header' 
  >
    <div class='nav'>
      <img
        src="@/assets/ZoomerCreditlogo.png"
        alt=""
        @click="goRouter('home')"
      >
      <ul v-if="isMobile">
        <li @click="goRouter('home')">Home</li>
        <li @click="goRouter('termsOfUse')">Terms of Use</li>
        <li @click="goRouter('privacyPolicy')">Privacy Policy</li>
        <li @click="goRouter('rates')">Rates</li>
        <li @click="goRouter('contactUs')">Contact Us</li>
      </ul>
    </div>
    <el-button
      v-if="isMobile"
      type="primary"
      round
      @click="tologin()"
    >LOG IN</el-button>
    <div 
      v-else
      class="drawer" 
      @click="navList(navState)"
    >
      <span v-if="navState"><img src="@/assets/colse.svg" /></span>
      <span v-else><img src="@/assets/nav-list.svg" /></span>
    </div>
    <div
      v-if="navState"
      class="navIsMobile" 
      :style="navHeight"
      @click="navList(navState)"
    >
      <ul>
        <li @click="goRouter('home')">Home</li>
        <li @click="goRouter('termsOfUse')">Terms of Use</li>
        <li @click="goRouter('privacyPolicy')">Privacy Policy</li>
        <li @click="goRouter('rates')">Rates</li>
        <li @click="goRouter('contactUs')">Contact Us</li>
      </ul>
      <div class="button-box">
        <el-button
          type="primary"
          round
          @click="tologin()"
        >LOG IN</el-button>
      </div>
    </div>
    <div class="cornerMark">
      <span>Customer Care Representative</span>
      <span class="phone">(303)418-4892</span>
      <span>Monday – Friday 9:00am – 6:00pm Eastern</span>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      isMobile: false,
      navState: false,
      navHeight: {
        height: 0
      }
    }
  },
  methods: {
    goRouter(value){
      this.$router.push({ name: value })
    },
    navList(value) {
      this.navState = !value
      if (this.navState) {
        // 手机端页面禁止滚动
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'scroll';
      }
    },
    tologin(){
      location.href = 'https://member.zoomercredit.com/MemberPage.aspx?subpage=customerLogin'
    }
  },
  mounted() {
    this.isMobile = this.$store.state.isMobile
    // 初始化页面滚动
    document.body.style.overflow = 'scroll';
    if (document.documentElement.clientHeight) {
      this.navHeight.height = document.documentElement.clientHeight + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
ul li{list-style: none;}
.header{
  width: 100%;
  box-sizing: border-box;
  background: #fff;     
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15%;
  // position: sticky;
  // top: 0;
  /deep/.el-button{
    width: 12%;
    background:linear-gradient(to right, rgb(250, 133, 66),#FF5E00);
    border:none;
  }
  .nav{
    display: flex;
    align-items: center;
    padding:2em 0;
    img{
      width: 15em;
      margin-right: 2em;
      cursor: pointer;
    }
    ul{
      display: flex;
      font-size: 1.8em;
      li{
        margin-right: 0.5em;
        cursor: pointer;
        padding:0 10px;
      }
    }
  }
  .login{
    width: 131px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #459EFF;
    font-size: 16px;
    color:#fff;
  }
  .cornerMark{
    position: absolute;
    right: 10px;
    display: flex;
    flex-direction: column;
    span{padding:3px 0;}
    .phone{color:#459EFF}
  }
}
.el-button.is-round{padding: 0.32rem 0rem;}
// 小屏
@media screen and (min-width:769px) and (max-width:1366px) {
  .header{padding: 0 50px;}
}

// 兼容手机
@media screen and (max-width: 768px) {
  .header{
    justify-content: space-between;
    padding: 0 1em;
    .button-box{
      margin: 0 auto;
      width: 95%;
      .el-button{width: 100%;margin-top: 2em;}
    }
    .drawer{
      position: relative;
      img{
        width: 2em;
      }
    }
    .navIsMobile{
      position: fixed;
      z-index: 100;
      top: 80px;
      left: 0;
      background: #fff;
      width: 100%;
      ul{
        li{
          font-size: 2em;
          border-bottom: 2px solid #ccc;
          padding: 1em;
        }
      }
    }
  }
}
</style>