import Vue from 'vue'
import Router from 'vue-router'
// import { Dialog } from 'vant'
import store from '@/store'

Vue.use(Router)

import routes from './routes'

const router = new Router({
  mode: 'history',
  routes
})

const whiteRoutes = ['/login']

// 防止路由重复报错
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 全局前置路由守卫
router.beforeEach(async (to, from, next) => {
  // console.log(to)
  let path = to.path
  if (whiteRoutes.includes(path)) {
    next()
    return
  }
  // store有没有token
  let token = store.state.token
  next()
  // // 未登录
  // if (!token) {
  //   next('/login')
  // } else {
  //   next()
  // }
})

export default router
