<template>
  <div class='header'>
    <div class='nav'>
      <img
        src="@/assets/ZoomerCreditlogo.png"
        alt=""
        @click="goRouter('home')"
      >
    </div>
    <div class='headerRight'>
      <!-- <span>Customer Care</span><i class="el-icon-user-solid"></i> -->
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      note:{
        backgroundImage:'url('+require('@/assets/page.png')+')',
        backgroundSize:'100% 100%',
      }
    }
  },
  methods: {
    goRouter(value){
      this.$router.push({ name: value })
    }, 
  }
  
}
</script>

<style lang="scss" scoped>
.header{      
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  /deep/.el-button{
    width: 12%;
  }
  .nav{
    display: flex;
    align-items: center;
    padding:2em 0;
    img{
      width: 15em;
      margin-right: 2em;
      cursor: pointer;
    }
    ul{
      display: flex;
      font-size: 16px;
      li{
        margin-right: 32px;
      }
    }
  }
  .login{
    width: 131px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #459EFF;
    font-size: 16px;
    color:#fff;
  }
}
.headerRight{
  font-size: 2em;
  span{
    margin-right: 1em;
  }
}
@media screen and (max-width: 768px) {
  .header{
    justify-content: center;
  }
}
</style>