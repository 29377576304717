
/* eslint-disable */

export default [
  {
    path: '/',
    name: 'home',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Home') },
    meta: '',
    children: []
  },
  {
    path: '/termsOfUse',
    name: 'termsOfUse',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Terms') },
    meta: '',
    children: []
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/ContactUs') },
    meta: '',
    children: []
  },
  {
    path: '/rates',
    name: 'rates',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Rates') },
    meta: '',
    children: []
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/PrivacyPolicy') },
    meta: '',
    children: []
  },
  {
    path: '/howItWorks',
    name: 'howItWorks',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/howItWorks') },
    meta: '',
    children: []
  },
  {
    path: '/WhoWeAre',
    name: 'whoWeAre',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/WhoWeAre') },
    meta: '',
    children: []
  },
  {
    path: '/process',
    name: 'process',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Process') },
    meta: '',
    children: []
  },
  {
    path: '/step1',
    name: 'step1',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step1') },
    meta: '',
    children: []
  },
  {
    path: '/step2',
    name: 'step2',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step2') },
    meta: '',
    children: []
  },
  {
    path: '/step3',
    name: 'step3',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step3') },
    meta: '',
    children: []
  },
  {
    path: '/step4',
    name: 'step4',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step4') },
    meta: '',
    children: []
  },
  {
    path: '/step5',
    name: 'step5',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step5') },
    meta: '',
    children: []
  },
  {
    path: '/step6',
    name: 'step6',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step6') },
    meta: '',
    children: []
  },
  {
    path: '/step7',
    name: 'step7',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step7') },
    meta: '',
    children: []
  },
  {
    path: '/step8',
    name: 'step8',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step8') },
    meta: '',
    children: []
  },
  {
    path: '/step9',
    name: 'step9',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Step9') },
    meta: '',
    children: []
  },
  {
    path: '/account',
    name: 'account',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Account') },
    meta: '',
    children: []
  },
  {
    path: '/accountLogin',
    name: 'accountLogin',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/AccountLogin') },
    meta: '',
    children: []
  },
  {
    path: '/profile',
    name: 'profile',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Profile') },
    meta: '',
    children: []
  },
  {
    path: '/loanHistory',
    name: 'loanHistory',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/LoanHistory') },
    meta: '',
    children: []
  },
  {
    path: '/missouri',
    name: 'missouri',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Missouri') },
    meta: '',
    children: []
  },
  {
    path: '/utah',
    name: 'utah',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Utah') },
    meta: '',
    children: []
  },
  {
    path: '/wisconsin',
    name: 'wisconsin',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Wisconsin') },
    meta: '',
    children: []
  },
  {
    path: '/accessibility',
    name: 'accessibility',
    component: function () { return import(/* webpackPrefetch: true, webpackChunkName: 'login' */ '@page/Accessibility') },
    meta: '',
    children: []
  }

]