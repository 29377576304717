import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5';
import Header from '@/components/header'
import StepHearder from '@/components/StepHearder'
import Header3 from '@/components/Header3'
import Footer from '@/components/footer'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en.js'
import './assets/css/global.css'

Vue.use(ElementUI,{ locale });
Vue.config.productionTip = false
Vue.prototype.$md5 = md5;
Vue.prototype.$alert = alert;
window.md5 = md5
Vue.component('headers', Header)
Vue.component('footers', Footer)
Vue.component('Headers3', Header3)
Vue.component('StepHearders', StepHearder)
router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})
// window.addEventListener('mousewheel', function(event){
//   if (event.ctrlKey === true || event.metaKey) {
//     event.preventDefault();
//   }
// }, { passive: false})
// //firefox
// window.addEventListener('DOMMouseScroll', function(event){
//   if (event.ctrlKey === true || event.metaKey) {
//     event.preventDefault()
//   }
// }, { passive: false})
window.addEventListener('load', adaptation)
window.addEventListener('resize', adaptation)
function adaptation () {
  var w = document.body.clientWidth
  var h = document.body.clientHeight
  var nw = 1920
  let nh = 1080
  var left, top, scale
  if (w / h > nw / nh) {
    scale = h / nh
    top = 0
    left = (w - nw * scale) / 2
  } else {
    scale = w / nw
    left = 0
    top = (h - nh * scale) / 2
  }
  // document.getElementById('main').setAttribute('style', 'transform: scale('+ scale +');left:'+left+'px;top:'+top+'px;')
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')