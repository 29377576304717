/**
 * 获取或者设置sessionStorage
 * @param {String} k - 键
 * @param {*} v - 值，指定此参数则代表设置，否则获取
 * @return {String|Object|Array} 不指定v时，返回sessionStorage中k的值
 */
export function sessionStorage (k, v) {
  if (v) {
    return window.sessionStorage.setItem(k, v)
  } else {
    return window.sessionStorage.getItem(k)
  }
}
// 格式化手机号
export function formatPhone (_phone) {
  let phone = _phone + ''
  // 去掉'+'
  if (phone.indexOf('+61') === 0) {
    return phone.slice(1)
  // 0开头，去掉0，再加61
  } else if (phone.indexOf('0') === 0) {
    return '61' + phone.slice(1)
  // '61开头'直接返回
  } else if (phone.indexOf('61') === 0) {
    return phone
  // 其他手机，追加61
  } else {
    return '61' + phone
  }
}
export function formatDate () {}
